import { ShipdaCurrentLanguage } from "@sellernote/_shared/src/i18n/i18nForShipda";
import ChevronLeftIcon from "@sellernote/_sds-v2/src/components/svgIcons/ChevronLeftIcon";
import ChevronRightIcon from "@sellernote/_sds-v2/src/components/svgIcons/ChevronRightIcon";

import { COLOR } from "../../../styles/colors";

import Styled from "./index.styles";

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return ShipdaCurrentLanguage.currentLanguage === "ko"
    ? `${year}년 ${month}월`
    : `${date.toLocaleDateString("en-US", { month: "long" })} ${year}`;
};

export default function CustomHeader({
  date,
  decreaseMonth,
  increaseMonth,
}: {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
}) {
  return (
    <Styled.customHeader>
      <ChevronLeftIcon
        width={16}
        height={16}
        color={COLOR.grayScale_600}
        onClick={decreaseMonth}
        cursor="pointer"
      />

      <span className="selected-month">{formatDate(date)}</span>

      <ChevronRightIcon
        width={16}
        height={16}
        color={COLOR.grayScale_600}
        onClick={increaseMonth}
        cursor="pointer"
      />
    </Styled.customHeader>
  );
}
