import Modal from "../../modal/Modal";

export default function AlertModal({
  showsAlertModal,
  onAlertModalClose,
  message,
}: {
  showsAlertModal: boolean;
  onAlertModalClose: () => void;
  message: string | undefined;
}) {
  return (
    <Modal
      // react-datepicker에서 사용하는 react-onclickoutside 기능(외부 클릭 시 캘린더가 닫힘)을 무시하기 위한 클래스명
      className="react-datepicker-ignore-onclickoutside"
      active={showsAlertModal}
      uiType="onlyDescription"
      desc={message}
      actionPositive={{ label: "확인", handleClick: onAlertModalClose }}
    />
  );
}
